// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

type DataProps = {
  site: {
    buildTime: string
  }
}

const UsingTypescript: React.FC<PageProps<DataProps>> = ({
  data,
  path,
  location,
}) => (
  <Layout title="Small Saucepan" location={location}>
    <SEO title="Pump Mate Mobile App" />
    <h1>Pump Mate</h1>
    <p>
      Pump Mate app is an easy to use app for Android to help firefighters
      calculate pump settings for various situations. It can calculate:
      <ul>
        <li>
          pump discharge pressure required to achieve a certain pressure and
          flow at the branch for different hose line configurations.
        </li>
        <li>
          possible hose line configurations for relaying over long distances,
          including presenting a "shopping list" of resources required so you
          can weigh up the various options.
        </li>
        <li>
          foam solution flow rates and overall foam concentrate amounts required
          for differing percentages, areas, and durations.
        </li>
      </ul>
    </p>
    <p>
      Note that the figures provided come without any warranty, and the safety
      of your pumping operation is solely your responsibility.
    </p>
    <p>
      <Link style={{ boxShadow: `none` }} to="./privacy">
        Privacy policy
      </Link>
      .
    </p>
  </Layout>
)

export default UsingTypescript

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
